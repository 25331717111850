<template>
  <div style="background: #F4F4F4" id="home">
    <div class="ht4"></div>
    <div class="homeUpSection">
      <div class="fs">
       <!-- 获取的code是: {{ code }} -->
        <!-- <div class="homePanel1 ml15 qcard">
          <div class="m12">
            <div class="hpt1">当前运行环境</div>
            <div class="fssb mt14">
              <div>
                <div class="hl22">
                  <span class="hpt2">移动端</span>
                </div>
                <div class="hpt3">
                  <span >{{isMobile ? '是' : '否'}}</span>
                </div>
                <div class="hl22 mt6">
                  <span class="hpt2">桌面端</span>
                </div>
                <div class="hpt3">
                  <span >{{isDesktop ? '是': '否'}}</span>
                </div>
              </div>
              <div>
                <div class="hl22">
                  <span class="hpt2">企业微信</span>
                </div>
                <div class="hpt3">
                  <span >{{isWXWork ? '是': '否'}}</span>
                </div>
                <div class="hl22 mt6">
                  <span class="hpt2">微信</span>
                </div>
                <div class="hpt3">
                  <span >{{isWeixin ? '是': '否'}}</span>
                </div>
              </div>
              <div>
                <div class="hl22">
                  <span class="hpt2">普通小程序</span>
                </div>
                <div class="hpt3">
                  <span >{{isMp && !isWXWork ? '是': '否'}}</span>
                </div>
                <div class="hl22 mt6">
                  <span class="hpt2">企微小程序</span>
                </div>
                <div class="hpt3">
                  <span >{{isMp && isWXWork ? '是': '否'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <form action="https://ct.ctrip.com/m/SingleSignOn/H5SignInfo" method="post"  target="_top">
        <input type="hidden" name="accessuserid" :value="accessuserid" />
        <input type="hidden" name="employeeid" :value="employeeid" />
        <input type="hidden" name="signature" :value="signature" />
        <input type="hidden" name="initpage" value="Home" />
        <input type="hidden" name="appid" :value="appid" />
        <input type="hidden" name="endorsementID" value="1234567" />
        <input type="hidden" name="token" :value="token" />
        <input type="hidden" name="onerror" value="errorcode" />
        <input value="单点登录" type="submit" onclick="a()"  id="cc" v-show="false"/>
    </form>
      </div>
    </div>

    <div class="homeMiddleSection">
      <div class="logo">
          <img src="../../assets/logo.jpg" class="logoimg">
        </div>
    </div>
      <div style="height: 40px"></div>
        <div class="homeBottomSection">
          <div class="tips"> {{ info1 }}</div>
          <!-- <van-empty description="当前没有出差信息" v-if="empty"/> -->
          <div style="height: 40px"></div>
          <!-- <van-button type="info" color="#115CA9" style="width: 330px" @click="login">
              <div class="syBtnTxtHome">登录</div>
            </van-button>
            <div style="height: 30px"></div>
            <van-button type="info" color="#115CA9" style="width: 330px" @click="TripCheckCTrip">
              <div class="syBtnTxtHome">获取参数</div>
            </van-button> -->
            <div style="height: 30px"></div>
          <!-- <van-button type="info" color="#115CA9" style="width: 330px" @click="handleOutWeixinClick" :disabled="!accessuserid">
              <div class="syBtnTxtHome">立即前往</div>
            </van-button> -->
            <!-- <van-button type="info" color="#115CA9" style="width: 330px" @click="relogin" :disabled="fail">
              <div class="syBtnTxtHome">重试</div>
            </van-button> -->
            <div style="height: 30px"></div>

        </div>

    <!-- <div class="homeBottomSection">
      <div class="fsb">
      <div class="sectionTitle">任务列表</div>
      <div class="sectionTitle"><van-icon name="location" />{{city}}</div>
      </div>
      <div class="homecard mb13 card-1" v-for="item in MissionList" :key="item.Id" @click="gotoP('missionDetail', item)">
        <div class="homecardIn">
          <div class="fs">
            <div class="fscc">
              <img :src="item.sLogo" class="Mlogo">
            </div>

            <div class="fssb" style="width: 100%">
              <div class="ml10">
                <div class="Mname">
                  {{item.sName}}
                </div>
                <div class="mt3">
                  <van-tag type="danger"><span class="tagTest">{{item.sCategoryName}}</span></van-tag>
                </div>
              </div>
              <div>
                <div class="dPricesHome" v-if="maskP === 0">
                  {{item.dPrices | currency()}}<span class="dPricesUnit">元</span>
                </div>
                <div class="dPricesHome" v-else>
                  {{item.dPrices | maskP()}}
                </div>
                <div class="mt4">
                  <div class="Tname" v-if="!maskP">
                    任务奖励
                  </div>
                </div>
              </div>
            </div>
          </div>
          <van-divider></van-divider>
          <div class="fssb syBtnHome mt10" style="fl">
            <div class="fsw">
            <div class="m2" v-if="item.iInternal">
               <van-tag type="success"><span class="tagTest" >内部</span></van-tag>
            </div>
            <div v-for="tag in item.tags" :key="tag"  class="m2">
               <van-tag color="#ffe1e1" text-color="#ad0000"><span class="tagTest" >{{tag}}</span></van-tag>
            </div>
            </div>
            <van-button round type="info" size="mini" color="#FAE00B">
              <div class="syBtnTxtHome">立即前往</div>
            </van-button>
          </div>
        </div>
      </div>
      <div class="ht15"></div>
      <van-tabbar v-model="active" @change="onChange" active-color="#111111" inactive-color="#999999" placeholder>
        <van-tabbar-item>
          <template #icon>
            <img src="../../assets/home_active.png">
          </template>
          首页
        </van-tabbar-item>
        <van-tabbar-item icon="setting-o">
          <template #icon>
            <img src="../../assets/my.png">
          </template>
          我的
        </van-tabbar-item>
      </van-tabbar>
    </div> -->
    <!-- {{ openlink }} -->
    <br>
    <!-- {{ ua }} -->
    <!-- {{ rq }} -->
    <br>
    <!-- {{ ua }} -->
    <!-- {{ href }} -->
    <!-- {{ showBtn }}
    移动端:{{isMobile}}
    微信端:{{isWeixin}}
    企业微信端: {{isWXWork }} -->
    <div v-html="html" style="width: 100px; height: 100px" v-show="showBtn"></div>
  </div>
</template>

<script>
import VConsole from 'vconsole';

const vConsole = new VConsole()
  import { Toast, Button, Tag, Dialog, Tabbar, TabbarItem, NoticeBar, Icon, Divider, Empty } from 'vant';
  import axios from 'axios'
  import wxu from '@/utils/wxu'
import { runInThisContext } from 'vm';
import WithDrawalVue from '../WithDrawal/WithDrawal.vue';
  export default {
    async created() {
      window.localStorage.removeItem('token')
      // let token = window.localStorage.getItem('token')
      // console.log(window.location.href.split('?')[0])
      let s = window.location.href.includes('code')
      let params = ''
      let pl = []
      let code = ''
      
      // window.alert(212)
      if (s) {
        params = window.location.href.split('?')[1]
        pl = params.split('&')
        let codestr = pl.find(i => i.startsWith('code'))
        code = codestr.split('=')[1]
        this.code = code
      } else {
        Toast.fail('错误的参数')
      }
      // this.getJsSdkConfig()
      // axios.defaults.headers.common['Token'] = token
      // await this.ClientGet()
      
      // let {data} = await this.$api.ClientGet()
      // console.log(data, 7878)
      // let {iAuditStatus} = data
      // this.iAuditStatus = Number(window.sessionStorage.getItem('iAuditStatus'))
      // this.iAuditStatus = iAuditStatus
      // this.getJsSdkConfig()
      // if (this.iAuditStatus !== 0) {
      //   this.ClientGet()
      // } 
      // if (this.iAuditStatus === 2) {
      //   this.CountInfoGet()
      //   this.getNotice()
      //   this.getMissionList()
      // } else {
      //   this.showConf()
      // }
    },

    mounted() {
      this.relogin()
      // if (window.history && window.history.pushState) {
      //   history.pushState(null, null, document.URL)
      //   window.addEventListener('popstate', this.goBack, false)
      // }
      // setTimeout(() => {
      //   // this.login()
      //   // this.checEnv()
      //   // this.getJsSdkConfig()
      // }, 300)
      // this.getJsSdkConfig()
      // this.getMissionList()
    },
    destroyed() {
      window.removeEventListener('popstate', this.goBack, false)
    },
    components: {
      [Tag.name]: Tag,
      [Button.name]: Button,
      [Dialog.name]: Dialog,
      [Dialog.Component.name]: Dialog.Component,
      [Tabbar.name]: Tabbar,
      [TabbarItem.name]: TabbarItem,
      [NoticeBar.name]: NoticeBar,
      [Icon.name]: Icon,
      [Divider.name]: Divider,
      [Empty.name]: Empty
    },
    computed: {
      p() {
        return this.$route.name
      },
      wxconfig() {
        return this.$store.state.wxconfig
      },
      initLink() {
        return this.$store.state.initLink
      },
      ad_info() {
        return this.$store.state.ad_info
      },
      mapKey() {
        return this.$store.state.mapKey
      }
    },
    data() {
      return {
        showBtn: false,
        code: '123456789',
        info1: '',
        fail: false,
        html:'<wx-open-launch-weapp id="launch-btn" username="gh_c0f130f8044a" path="/pages/main/main.html" @launch="handleLaunchFn" @error="handleErrorFn"><template><style>.btn { display: flex;align-items: center; }</style><button class="btn" style="width: 100%; height: 100px">跳转小程序</button></template></wx-open-launch-weapp>',
        maskP: 0,
        MissionList: [],
        ua: '',
        page: 1,
        rows: 99,
        show3: false,
        active: 0,
        latitude: '',
        longitude: '',
        title: '',
        sContent: '',
        btnS: true,
        isWeixin: 0,
        isMobile: 0,
        isWXWork: 0,
        isDesktop: 0,
        openlink: '',
        isMp: 0,
        city: '',
        rq: {},
        href: '',
        baseInfo: {

        },
        cd: 6,
        countInfo: {

        },
        s: 'ruok',
        accessuserid: '', 
        appid: '', 
        employeeid: '',
        signature: '',
        token: ''
      }
    },
    methods: {
      finish() {
        this.btnS = false
      },
      showNotice () {
        if (this.iAuditStatus === 2) {
          this.show3 = true
        } else {
          Toast.fail('您的账号还未注册，或在审核中')
        }
      },
      handleLaunchFn (e) {

      },
      handleErrorFn(e) {
          console.log('fail', e.detail);
      },
      async login () {
        this.fail = false
        Toast.loading({
          message: '获取人员信息...',
          forbidClick: true,
          overlay: true,
          duration: 0
        })
        let {data, success, code, info} = await this.$api.login({code: this.code})
        Toast.clear()
        if (success) {
          window.localStorage.setItem('token', data.token)
          this.TripCheckCTrip()
        } else {
          this.fail = true
          Toast.fail(info)
        }
        // let btn = window.document.querySelector('#cc')
        // btn.click()
      },
      async TripCheckCTrip () {
        Toast.loading({
          message: '获取人员出差信息...',
          forbidClick: true,
          overlay: true,
          duration: 0
        })
        let {data, success, code, info} = await this.$api.TripCheckCTrip()
        Toast.clear()
        if (success) {
          let {accessuserid, appid, employeeid,signature,token} = data
          this.accessuserid = accessuserid
          this.appid = appid
          this.employeeid = employeeid
          this.signature = signature
          this.token = token
          setTimeout(() => {
            this.handleOutWeixinClick()
          }, 200)
          // this.handleOutWeixinClick()
        } else {
          // if (code === 406) {
          //   this.empty = true
          // }
          this.fail = true
          this.info1 = info
          Toast.fail(info)
        }
      },
      relogin () {
        this.info1 = '即将前往携程商旅'
        this.login()
      },
      checEnv () {
        var ua = navigator.userAgent.toLowerCase()
        this.ua = ua
        var isWXWork = ua.match(/wxwork/i) == 'wxwork'
        var isMp = ua.match(/miniprogram/i) == 'miniprogram'
        var isWeixin = !isWXWork && ua.match(/MicroMessenger/i) == 'micromessenger'
        var isMobile = false
        var isDesktop = false
        if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
          isMobile = true
        } else {
          isDesktop = true
        }
        console.warn('ua', ua)
        console.warn(ua.match(/MicroMessenger/i) == 'micromessenger')
        var m = ua.match(/MicroMessenger/i)
        console.warn(m && m[0] === 'micromessenger')
        console.log(isMobile, isWeixin, isDesktop)
        this.isMobile = isMobile
        this.isWeixin = isWeixin
        this.isWXWork = isWXWork
        this.isDesktop = isDesktop
        this.isMp = isMp
        console.log('sdadsadsa')
        // this.CreateGenerateScheme()
        if (this.isMobile && this.isWXWork) {
          window.location.href = 'weixin://dl/business/?t=I3VSAk75YFa'
          window.open('weixin://dl/business/?t=I3VSAk75YFa')
        } else if (isWeixin && !isWXWork){
          window.location.href = 'weixin://dl/business/?t=I3VSAk75YFa'
          window.open('weixin://dl/business/?t=I3VSAk75YFa')
          // this.getJsSdkConfig()
        } else {
          try {
            this.CreateGenerateScheme()
            // console.log(window.location.href)
            // window.alert(window.location.href)
            // this.href = window.location.href
            // location.href = 'weixin://dl/business/?t=IblqQtudo5a'
            // window.location.href = 'weixin://dl/business/?t=IblqQtudo5a'
            // window.open('weixin://dl/business/?t=IblqQtudo5a')
            // console.log(765)
            // window.open('weixin://dl/business/?t=IblqQtudo5a', "_self")
            // console.log(window)
            // this.CreateGenerateScheme()
          } catch (e) {
            window.alert(e)
          }
        }
      },
      async handleOutWeixinClick2() {
        
        // await this.CreateGenerateScheme()
        if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
          location.href = 'weixin://dl/business/?t=IblqQtudo5a'
          // location.href = `opendefaultbrowser:weixin://dl/business/?t=IblqQtudo5a`
        } else {
          window.open('weixin://dl/business/?t=IblqQtudo5a')
        }
      },
      async handleOutWeixinClick() {
        // await this.CreateGenerateScheme()
        this.ss = true
        let btn = window.document.querySelector('#cc')
        btn.click()
        // if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        //   location.href = `opendefaultbrowser:${this.openlink}`
        // } else {
        //   window.open(this.openlink)
        // }
      //  window.location.href = '/dealSuccess/' + orderNum
      //   window.location.href = 'weixin://dl/business/?t=I3VSAk75YFa'
      
      },
      async CreateGenerateScheme () {
        Toast.loading({
          message: '请求中...',
          forbidClick: true,
          overlay: true,
          duration: 0,
        })
        let params = {
          code: this.code,
          path: 'pages/pre/pre',
          query: 'loginSource=3',
          env_version: 'trial'
        }
        let res = await this.$api.CreateGenerateScheme(params)
        Toast.clear()
        this.rq = res
        let {success, code, info, data} = res
        if (success) {
          this.openlink = data.openlink
        } else {
          Toast.fail(info)
        }
      },
      async getJsSdkConfig() {
        let self = this
        let url = window.__wxjs_is_wkwebview ? window.location.href.split('#')[0] : window.location.href
        url = window.location.href.split('#')[0]
        // url = 'http://www.baidu.com'
        // url = 'http://zqb.sczhbf.com/'
        // console.log(url)
        // console.log(window.__wxjs_is_wkwebview)
        // let Toast = Toast
        wxu.init([
          'getLocation'
        ], url).then((wx, res) => {
          this.s = JSON.stringify(res)
          console.log(res, 99090909090)
          // 这里写微信的接口
        }), function(err) {
          Toast.fail(err)
          console.log(111, err)
        }
      },
      rarp() {
        let self = this
        let resUrl = `http://apis.map.qq.com/ws/geocoder/v1`
        let params = {
          location: this.latitude + ',' + this.longitude,
          key: this.mapKey,
          get_poi: 0,
          // coord_type: 'gcj02',//输入的locations的坐标类型,1 GPS坐标
          output: 'jsonp',
        }
        this.$jsonp(resUrl, params)
          .then((res) => {
            let { ad_info, address } = res.result
            this.city = ad_info.city
            self.$store.dispatch('aLoc', { ad_info, address })
          }).catch((e) => {
            console.log(e)
          })
      },
      async getNotice() {
        // this.$refs['countDown'].reset()
        let Id = window.sessionStorage.getItem('newNotice')
        let { data, info, success } = await this.$api.getNewNotice()
        if (success) {
          this.title = data.sTitle
          window.sessionStorage.setItem('newNotice', data.Id)
          this.sContent = decodeURI(data.sContent)
          this.btnS = true
          if (Id === data.Id) {
            return
          }
          this.cd = 6,
          setInterval(() => {
            if (this.cd >= 1) {
              this.cd -=1
            }
          }, 1000)
          this.show3 = true
          // console.log(this.$refs)
          // // this.$refs['countDown'].start()
        } else {
          Toast.fail(info)
        }
      },
      showConf() {
        this.$router.push('submit')
      },
      async ClientGet() {
        // let bi = window.sessionStorage.getItem('bi')
        let res
        // if (bi) {
        //   res = {data: JSON.parse(bi), info: 'info', success: true}
        // } else {
        res = await this.$api.ClientGet()
        
        let {data, info, success} = res
        if (success) {
          this.baseInfo = data
          let bi = JSON.stringify(data)
          // if (data.iPrivate === 1) {
          //   window.localStorage.setItem('maskP', data.iPrivate)
          //   this.maskP = 1
          // }
          this.iAuditStatus = data.iAuditStatus
          window.sessionStorage.setItem('bi', bi)
          if (data.iAuditStatus === 2) {
            this.CountInfoGet()
            this.getNotice()
            this.getMissionList()
          } else {
            this.showConf()
          }
        } else {
          Toast.fail(info)
        }
      },
      async CountInfoGet() {
        let { data, info, success } = await this.$api.GetCountInfo()
        if (success) {
          console.log(data)
          this.countInfo = data
        } else {
          Toast.fail(info)
        }
      },
      async getMissionList() {
        let params = {
          page: this.page,
          rows: this.rows,
          sort: 'iSort desc,dInsertTime',
          order: 'desc'
        }
        let { data, success } = await this.$api.getMissonList(params)
        if (success) {
          this.MissionList = data.rows.map(item => {
            item.tags = item.sTag ? item.sTag.split(',') : []
            return item
          })
        }
      },
      goto(path) {
        console.log(path)
        // if (path === 'orderCount') {
        //   window.sessionStorage.setItem('oi', 1)
        // }
        this.$router.push(path)
      },
      gotoP(path, { Id, dPrices }) {
        this.$router.push({ path, query: { Id, dOrderPrices: dPrices } })
      },
      onChange(index) {
        if (index === 1) {
          this.goto('/my')
        }
      }
    }
  };
</script>

<style>
  .tzSection {
    padding: 15px;
  }
  .logoimg {
    /* width: 70px; */
    height: 60px;
    border-radius: 8px;
  }
  .tz {
    border-radius: 9px;
  }

  .homeUpSection {
    height: 15vh;
    background: transparent;
    /* padding-bottom: 35px; */
  }
  .tips {
    padding: 20px;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .homeMiddleSection {
    height: 35vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-bottom: 1px solid #EEE;
    /* background: linear-gradient(180deg, #FAE00B, #F4F4F4); */
  }

  .homeBottomSection {
    /* background: #fbfbfb; */
    /* background: #f8f8f8; */
    background: transparent;
    height: 50vh;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
  }

  .p15 {
    padding: 0 15px;
  }

  .p10 {
    padding: 0 10px;
  }
  .homecard {
    width: 345px;
    /* height: 150px; */
    background: #FFFFFF;
    border: 1px white solid;
    /* box-shadow: 0px 3px 6px 0px rgba(34, 34, 34, 0.1); */
    border-radius: 16px;
  }

  .card-1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
    /* transition: all 0.3s cubic-bezier(.25,.8,.25,1)   */
  }

  .card-2 {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.08);
    /* transition: all 0.3s cubic-bezier(.25,.8,.25,1)   */
  }

  .homecardIn {
    margin: 10px 10px;
  }

  .Mlogo {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  .Mname {
    color: #444;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: bold;
  }

  .CnameHome {
    background: #F53F46;
    color: white;
    /* letter-spacing: 1px; */
    font-size: 12px;
    /* text-align: center; */
    border-radius: 2px;
    padding: 0 5px;
    /* width: 62px; */
  }

  .PnameHome {
    /* text-align: center; */
    /* background: #e6e6e6; */
    color: #777;
    font-size: 12px;
    /* padding: 0 5px; */
    /* font-weight: bold; */
  }

  .dPricesHome {
    color: #F53F46;
    font-size: 18px;
    text-align: right;
    font-family: 'DC';
    font-weight: 600;
    letter-spacing: 1px;
  }

  .dPricesUnit {
    font-size: 12px !important;
    display: inline-block;
    font-weight: 400 !important;
    margin-left: 2px;
  }

  .Tname {
    color: #b0b0b0;
    letter-spacing: 1px;
    font-size: 10px
  }

  .syBtnHome {
    /* height: 64px; */
  }

  .syBtnTxtHome {
    color:white;
    width: 74px;
    letter-spacing: 1px;
    font-size: 12px
  }

  .tagTest {
    font-size: 11px;
    letter-spacing: 1px;
  }

  .homenotice {
    height: 400px;
    padding: 10px;
    overflow: auto
  }
  .syBtnTxtBrss{
  color: black;
  width: 44px;
  font-size:12px
}
 #home .van-notice-bar {
   border-radius: 10px;
   width: 315px;
 }
 .noticeBtn {
   /* position: relative;
   top: 100px;
   z-index: 3999; */

 }
</style>
import wx from 'weixin-js-sdk'
import {store} from '@/store'
import api from '@/requests'

const getSignature = function (url) {
  // url = url.split('#')[0]
  return api.getJsSdkConfig({sUrl: url})
}
export default {
  /* 初始化wxjsdk各种接口 */
  init(apiList = [], url, Toast) {
    //需要使用的api列表
    return new Promise((resolve, reject) => {
      console.log(store.state.initLink)
      let surl = url || store.state.initLink
      // Toast.success(surl)
      // window.alert(surl)
      getSignature(surl).then(res => {
        if (res.success) {
          console.log(res, 989898)
          wx.config({
            debug: true, 
            appId: res.data.appId,
            timestamp: res.data.timestamp, 
            nonceStr: res.data.nonceStr, 
            signature: res.data.signature,
            jsApiList: apiList,
            openTagList:['wx-open-launch-weapp']
          })
          wx.ready(res => {
            // 微信SDK准备就绪后执行的回调。
            resolve(wx, res)
          })
        } else {
          reject(res)
        }
      })
    })
  }
}
<template>
  <div>
    <van-nav-bar title="提现" left-arrow @click-left="onClickLeft" />
    <div class="syUpsection fsac">
      <div id="subup">
        <div class="balance fscc">
          <div>
            <div class="wdTitle">当前余额(元)</div>
            <div class="wdV" v-if="!maskP">{{baseInfo.dBalance | currency()}}</div>
            <div class="wdV" v-else>{{baseInfo.dBalance | maskP('*')}}</div>
          </div>
        </div>
        <!-- <van-uploader v-model="fileList" upload-text="请上传你的门头照" :after-read="onRead" :show-upload="!sShopImage" @delete="deleteImg"/> -->
      </div>
    </div>
    <div class="syBotsection mt10">
      <van-cell is-link @click="show=true">
        <template #title >
          <div class="fscc cellTitle">
            <div class="cellTileI">
              <van-icon name="cashier-o" size="5.5vw"/>
            </div>
            <div class="custom-title" >提现方式</div>
          </div> 
        </template>
      </van-cell>
      <div class="ht10"></div>
      <van-cell v-if="iType === 1">
        <template #title>
          <div class="fscc cellTitle">

            <div class="cellTileI">
              <van-icon name="wechat" color="#04BE02" size="5.5vw"/>
            </div>
            <div class="custom-title" >提现到微信零钱</div>
          </div>
          <div class="custom-desc mt10">{{baseInfo.sNickName}} 提现金额1-200元</div>
        </template>
      </van-cell>
      <div v-if="iType === 2">
        <van-cell>
          <template #title>
            <div class="fscc cellTitle">
              <div class="cellTileI fsac">
                <van-icon name="alipay"  color="#1678ff" size="5.5vw"/>
              </div>
              <div class="custom-title">提现到支付宝</div>
            </div>
          </template>
        </van-cell>
        <van-field v-model="sAccount"  class="mt-12" placeholder="支付宝账号">
                  <template #right-icon>
          <div class="fscc cellTitle">
            <div>单笔1~2000元</div>
          </div>
        </template>
        </van-field>
      </div>
      <div v-if="iType === 3">
        <van-cell>
          <template #title>
            <div class="fscc cellTitle">
              <div class="cellTileI fsac">
                <van-icon name="alipay"  color="#1678ff" size="5.5vw"/>
              </div>
              <div class="custom-title">线下转账到支付宝</div>
            </div>
          </template>
        </van-cell>
        <van-field v-model="sAccount"  class="mt-12" placeholder="支付宝账号">
                  <template #right-icon>
          <div class="fscc cellTitle">
            <div>单笔1~2000元</div>
          </div>
        </template>
        </van-field>
      </div>
      <div v-if="iType === 2 || iType ===3">
        <van-cell>
          <template #title>
            <div class="fscc cellTitle">
              <div class="cellTileI fsac">
                <van-icon name="manager"  color="#1678ff" size="5.5vw"/>
              </div>
              <div class="custom-title">姓名</div>
            </div>
          </template>
        </van-cell>
        <van-field v-model="sName"  class="mt-12" placeholder="支付宝账号绑定人的姓名">
        </van-field>
      </div>
      <!-- <van-cell v-if="iType === 1">
        <template #title>
          <div class="fscc cellTitle">

            <div class="cellTileI">
              <van-icon name="alipay" color="#1678ff" size="5.5vw"/>
            </div>
            <div class="custom-title" >提现到支付宝</div>
          </div>
          <div class="custom-desc mt10">{{baseInfo.sNickName}} 提现金额1-200元</div>
        </template>
      </van-cell> -->
      <!-- <div class="sectionTitle border_b p15">注册信息</div> -->
      <!-- <van-field v-model="sName" label="姓名" left-icon="smile-o"/> -->
      <!-- 输入手机号，调起手机号键盘 -->
      <div>
        <!-- <van-cell>
          <template #title>
            <div class="fscc cellTitle">
              <div class="cellTileI fsac">
                <van-icon name="wechat" color="#04BE02" size="5.5vw"/>
              </div>
              <div class="custom-title">提现到微信</div>
            </div>
          </template>
        </van-cell> -->
      </div>
      <van-cell class="mt-12">
        <template #right-icon>
          <!-- <div class="fscc cellTitle">
            <div class="right-btn" @click="tx(true)">全部提现</div>
          </div> -->
        </template>
      </van-cell>
      <div>
        <van-cell>
          <template #title>
            <div class="fscc cellTitle">
              <div class="cellTileI fsac">
                <van-icon name="bill"  size="5vw"/>
              </div>
              <div class="custom-title">提现金额</div>
            </div>
          </template>
        </van-cell>
      </div>
      <van-field v-model="dPrices" type="number" class="mt-12">
        <template #right-icon>
          <div class="fscc cellTitle">
            <div class="right-btn" @click="tx(true)">全部提现</div>
          </div>
        </template>
      </van-field>
      <div class="ht90"></div>
      <div class="fsac mt10 ml10 mr10">
        <van-button type="info" size="large" round @click="tx(false)" color="#FDE415">
          <div class="syBtnTxt2">提现</div>
        </van-button>
      </div>
    </div>
    <van-action-sheet  v-model="show" :actions="option1" @select="onSelect" />
  </div>
</template>

<script>
  import { Card, Toast, Button, Field, Cell, NavBar,Icon, ActionSheet } from 'vant'
  export default {
    components: {
      [Card.name]: Card,
      [Cell.name]: Cell,
      [Field.name]: Field,
      [Button.name]: Button,
      [NavBar.name]: NavBar,
      [Icon.name]: Icon,
      [ActionSheet.name]: ActionSheet
    },
    mounted() {
      let maskP = window.localStorage.getItem('maskP')
      if (maskP) {
        this.maskP = Number(maskP)
      }
      let params = window.localStorage.getItem('sParam') ? JSON.parse(window.localStorage.getItem('sParam')) : {}
      let {iType, sAccount, sName} = params
      this.iType = iType
      this.sAccount = sAccount
      this.sName = sName
      this.ClientGet2()
      // this.iAuditStatus = Number(window.sessionStorage.getItem('iAuditStatus'))
      // if (this.iAuditStatus !== 0) {
      //   this.ClientGet()
      //   // this.getBonusRecord()
      // }
    },
    data() {
      return {
        maskP: 0,
        baseInfo: {},
        dPrices: '',
        sAccount: '',
        sName: '',
        option1: [
          { name: '微信', value: 1 },
          { name: '支付宝', value: 2 },
          { name: '线下转账', value: 3 }
        ],
        iType: 1,
        show: false
      }
    },

    computed: {},

    methods: {
      onClickLeft() {
        this.$router.go(-1)
      },
      goto(path) {
        this.$router.push(path)
      },
      onSelect ({name, value}) {
        this.iType = value
        this.show = false
      },
      async ClientGet2() {
        let bi = window.sessionStorage.getItem('bi')
        let res
        if (bi) {
          res = {data: JSON.parse(bi), info: 'info', success: true}
        } else {
          res = await this.$api.ClientGet()
        }
        let {data, info, success} = res
        if (success) {
          this.baseInfo = data
          let bi = JSON.stringify(data)
          this.iAuditStatus = data.iAuditStatus
          window.sessionStorage.setItem('bi', bi)
        } else {
          Toast.fail(info)
        }
      },
      async tx(s = false) {
        if (!this.iType) {
          Toast.fail('您未选择提现方式')
          return
        }
        if (this.iType !== 1 && !this.sAccount) {
          Toast.fail('您未填写提现支付宝账号')
          return
        }
        if (this.iType !== 1 && !this.sName) {
          Toast.fail('您未填写姓名')
          return
        }
        if (s) {
          this.dPrices = this.baseInfo.dBalance
          return
        }
        if (this.dPrices === 0) {
          Toast.fail('您的余额为0')
          return
        }
        if (this.dPrices < 1) {
          Toast.fail('提现额度至少为1元')
          return
        }
        if (this.iType === 1 && this.dPrices > 200) {
          Toast.fail('微信零钱单次提现额度不能超过200')
          return
        }
        if (this.iType !== 1 && this.dPrices > 2000) {
          Toast.fail('单笔支付不能超过2000')
          return
        }
        if (this.dPrices > this.baseInfo.dBalance) {
          Toast.fail('提现额度超过余额')
          this.dPrices = this.baseInfo.dBalance
          return
        }
        let params = {
          iType: this.iType,
          dPrices: this.dPrices,
          sAccount: this.sAccount
        }
        if (this.iType === 2 || this.iType === 3) {
          params.sName = this.sName
        }
        let { info, success } = await this.$api.WithDrawalInsert(params)
        if (success) {
          window.localStorage.setItem('sParam', JSON.stringify(params))
          // Toast.success('提现请求已发出')
          await this.ClientGet2()
          Toast.loading({
            message: '提现请求已发出',
            forbidClick: true,
            overlay: true,
            duration: 2000
          })
          this.$router.go(-1)
        } else {
          Toast.fail(info)
        }
      },
      async ClientGet() {
        let bi = window.sessionStorage.getItem('bi')
        this.baseInfo = JSON.parse(bi)
      }
    }
  };
</script>

<style>
  .balance {
    width: 100%;
    height: 123px !important;
  }

  .cellTile {
    height: 20px;
    line-height: 20px;
    color: #111;
    font-size: 16px !important;
  }

  .cellTileI {
    height: 8vw;
    line-height: 8vw;
    /* width: 18px;
    height: 18px; */
    position: relative;
    top: 0.5vw;
    display: block;
    margin-right: 4px;
  }

  .custom-title {
    color: #111;
    font-size: 16px !important;
  }

  .custom-desc {
    color: #454545;
    margin-left: 22px;
  }

  .right-btn {
    color: #F53F46;
    font-size: 14px;
  }

  .wdTitle {
    color: #333;
    font-size: 16px;
    letter-spacing: 1px;
    position: relative;
    top: -10px
  }

  .wdV {
    font-weight: bold;
    font-size: 36px;
    color: #111;
    /* position: relative;
  top: px */
  }
</style>
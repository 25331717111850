<template>
  <div>
    <div style="width: 99vw; height: 95vh" class="fsac">
    <van-loading type="spinner" >
      检测中...
    </van-loading>
    </div>
      <!-- <button @click="getCode">haha</button> -->
      <!-- {{store}} -->
  </div>
</template>

<script>
import { Loading, Toast } from 'vant';
import axios from 'axios'
const S = window.sessionStorage
const L = window.localStorage
export default {
  mounted () {
    let lastLoginTime = L.getItem('l1')
    if (lastLoginTime) {
      this.$router.replace('/home')
      return
    }
    this.test()
  },
  components: {
    [Loading.name]: Loading
  },
  data() {
    return { 
    }
  },
  computed: {
  },
  methods: {
    async test () {
      console.log('test!!!!!!!!!!!!!!!!!!!!!!!!')
      let urlParams = new URLSearchParams(window.location.search)
      let sCode = urlParams.get('code')
      let {data,info, success} = await this.$api.wxLogin({sCode})
      if (success) {
        let t = new Date().getTime() / 1000
        axios.defaults.headers.common['Token'] = `${data.sToken}`
        L.setItem('l1', t)
        L.setItem('token', data.sToken)
        // window.sessionStorage.setItem('iAuditStatus', data.iAuditStatus)
        if (data.iAuditStatus === 2 || data.iAuditStatus === 1) {
          let {sName, sPhone, sShopImage, sShopName} = data
          window.sessionStorage.setItem('si', JSON.stringify({sName, sPhone, sShopImage, sShopName}))
        }
        if (data.iAuditStatus === 2) {
          this.$router.replace('/home')
        } else {
          this.$router.replace('/submit')
        }
      } else {
        Toast.fail(info)
      }
      console.log(data)
    },
  }
};
</script>

<style lang="less">
</style>

<template>
  <div >
    <div style="width: 99vw; height: 95vh" class="fsac">
    <van-loading type="spinner" >
      加载中...
    </van-loading>
    </div>
      <!-- <button @click="getCode">haha</button> -->
  </div>
</template>

<script>
import { Loading } from 'vant'
const S = window.sessionStorage
const L = window.localStorage
// import axios from 'axios'
export default {
  mounted () {
    let s = window.location.href
    // let urlParams = new URLSearchParams(window.location.search)
    // this.getCode()
    // let t = new Date().getTime() / 1000
    let lastLoginTime = L.getItem('l1')
    if (lastLoginTime) {
      this.$router.replace('/home')
      return
    }
    // this.getCode2()
    if (!s.includes('code')) {
      this.getCode2()
    } else {
      this.$router.replace('/')
    }
  },
  components: {
    [Loading.name]: Loading
  },
  data() {
    return {
      rdi_url: 'http://zqb.sczhbf.com/#/check',
      // rdi_url: 'http://mission.ltgirl.com/#/check',
      // APPID: 'wx260ad2e8f79464f5',
      APPID: 'wx04f202847bc230b7',
      SCOPE: 'snsapi_userinfo'
    }
  },
  methods: {
    async getCode () {
      // this.$api.wxLogin({})
      let rdi_url = encodeURIComponent(this.rdi_url)
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${rdi_url}&response_type=code&scope=${this.SCOPE}#wechat_redirect`
      window.location.href = url
      // console.log(data)
    },
    async getCode2 () {
      let {data} = await this.$api.wxLogin({sUrl: this.rdi_url})
      window.location.href = data
      // console.log(data)
    }
  }
};
</script>

<style lang="less">
</style>

import Vue from 'vue';
import Router from 'vue-router';
const S = window.sessionStorage
const L = window.localStorage
Vue.use(Router);
import index from './view/index/index'
import check from './view/check/check.vue'
import home from './view/home/home'
// import sy from './view/sy/sy'
// import my from './view/my/my'
// import team from './view/my/team'
// import notice from './view/my/notice'
// import summary from './view/my/summary'
// import brsl from './view/my/brsl'
// import brss from './view/my/brss'
// import invite from './view/my/invite'
// import missionDetail from './view/missionDetail/missionDetail'
// import commit from './view/missionDetail/commit'
// import order from './view/order/order'
// import rank from './view/rank/rank'
// import WithDrawal from './view/WithDrawal/WithDrawal'
// import orderDetail from './view/orderDetail/orderDetail'
// import pOrder from './view/order/pOrder'
// import submit from './view/submit/submit'
const routes = [
  {
    path: '*',
    redirect: () => {
      // return '/home'
      // let needLogin = L.getItem('needLogin')
      // let lastLoginTime = L.getItem('l1')
      // let t = new Date().getTime() / 1000
      // if (needLogin) {
      //   // L.removeItem('token')
      //   L.removeItem('l1')
      //   L.removeItem('needLogin')
      //   return '/index'
      // } else if (!lastLoginTime) {
      //   return '/index'
      // } else if (t - Number(lastLoginTime) > 21600) {
      //   L.removeItem('l1')
      //   return '/index'
      // } else {
      //   return '/home'
      // }
    }
  },
  // {
  //   name: 'user',
  //   component: () => import('./view/user'),
  //   meta: {
  //     title: '会员中心'
  //   }
  // },
  {
    name: 'index',
    component: index,
    meta: {
      title: '授权'
    }
  },
  {
    name: 'check',
    component: check,
    meta: {
      title: '检测'
    }
  },
  {
    name: 'home',
    component: home,
    meta: {
      title: '跳转',
      allowBack: false
    }
  }
  // {
  //   name: 'goods',
  //   component: () => import('./view/goods'),
  //   meta: {
  //     title: '商品详情'
  //   }
  // }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};

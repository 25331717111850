import axios from 'axios'
const prefix = '/admin'
import Vue from 'vue'
const S = window.sessionStorage
const L = window.localStorage
axios.validateStatus = function (status) {
  return (status >= 200 && status < 300) || status === 500 || status === 406// 默认值
}
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 返回 401 清除 token 信息并跳转到登录页面
          // store.commit(types.LOGOUT)
          if (window.sessionStorage.getItem('rdic')) {
            return {status: 401, data: error.response.data}
          }
          return {status: 401, data: error.response.data}
        case 406:
          return {status: 200, data: error.response.data}
      }
    }
    return Promise.reject(error.response.data)
    // 返回接口返回的错误信息
  }
)
async function get (url, params = {}) {
  let allData
  let token = L.getItem('token')
  let headers = token ? {'Token': token} : {}
  try {
    let {data} = await axios.get(url, {params, headers}, )
    // Vue.$route.replace('index')
    allData = data
  } catch (e) {
    allData = { success: false, info: '网络错误' }
  }
  let {authentication} = allData
  if ('authentication' in allData && !authentication) {
    window.sessionStorage.removeItem('l1')
    window.location.replace('/')
    // Vue.$router.replace('index')
  }
  return allData
}
async function post (url, params) {
  let allData
  let formData = new window.FormData()
  for (let key in params) {
    let value = params[key]
    // if (value === 0) value = '0'
    // if (!value) continue
    if (typeof (value) === 'object') {
      for (let i in value) {
        formData.append(key + '[]', value[i])
      }
    } else {
      formData.append(key, value)
    }
  }
  let token = L.getItem('token')
  let headers = token ? {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', 'Token': token} : {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}
  // formData.append('Sign', sign(params))
  try {
    let {data} = await axios.post(url, formData, {headers})
    allData = data
  } catch (e) {
    allData = { success: false, info: '网络错误' }
  }
  let {authentication} = allData
  if ('authentication' in allData && !authentication) {
    window.sessionStorage.setItem('l1')
    window.location.replace('/')
    // Vue.$router.replace('index')
  }
  return allData
}

async function del (url, params = {}) {
  let allData
  try {
    let {data} = await axios.delete(url, {params})
    allData = data
  } catch (e) {
    allData = { success: false, info: '网络错误' }
  }
  return allData
}
// async function getFile (url, params = {}, filename = '') {
//   axios({
//     url,
//     method: 'get',
//     params,
//     responseType: 'blob'
//   }).then(res => {
//     /* 兼容ie内核，360浏览器的兼容模式 */
//     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//       let blob = new Blob([res.data])
//       window.navigator.msSaveOrOpenBlob(blob, filename)
//     } else {
//     /* 火狐谷歌的文件下载方式 */
//       var blob = new Blob([res.data])
//       var downloadElement = document.createElement('a')
//       var href = window.URL.createObjectURL(blob)
//       downloadElement.href = href
//       downloadElement.download = filename
//       document.body.appendChild(downloadElement)
//       downloadElement.click()
//       document.body.removeChild(downloadElement)
//       window.URL.revokeObjectURL(href)
//     }
//   }).catch(res => {
//     console.log(res)
//   })
// }

export default {
  wxLogin (params = {}) { // 登陆
    return post(prefix + '/Client/AuthLogin', params)
  },
  login (params = {}) { // 登陆
    return post(prefix + '/pm/login/ctriplogin', params)
  },
  TripCheckCTrip (params = {}) { // 获取form参数
    return post(prefix + '/pm/trip/checkctrip', params)
  },
  register (params = {}) { // 注册
    return post(prefix + '/Client/Register', params)
  },
  CreateGenerateScheme (params = {}) { // 获取链接
    return post(prefix + '/mini/WeChat/CreateGenerateScheme', params)
  },
  getJsSdkConfig (params = {}) { // 获取jssdk配置
    return get(prefix + '/WeChat/GetConfig', params)
  },
  ClientGet (params = {}) { // 获取会员基本信息
    return get(prefix + '/Client/Get', params)
  },
  checkCode (params = {}) { // 验证邀请吗是否有效
    return post(prefix + '/Client/IsVaildByCode', params)
  },
  getAuthCode (params = {}) { // 获取短信验证码
    return get(prefix + '/Client/GetAuthCode', params)
  },
  getMissonList (params = {}) { // 获取任务列表
    return get(prefix + '/Mission/GetList', params)
  },
  getMissonDetail (params = {}) { // 获取任务详情
    return get(prefix + '/Mission/Get', params)
  },
  getOrderDetail (params = {}) { // 获取订单详情
    // return get(prefix + '/Mission/GetOrderDetail', params)
    return get(prefix + '/Order/Get', params)
  },
  OrderInsert (params = {}) {
    return post(prefix + '/Order/Insert', params)
  },
  OrderCancel (params = {}) {
    return post(prefix + '/Order/Cancel', params)
  },
  OrderCommit(params = {}) { // 提交凭证
    return post(prefix + '/Order/Commit', params)
  },
  OrderCommitDirectly(params = {}) { // 直接提交凭证
    return post(prefix + '/Order/CommitByDirectly', params)
  },
  getOrders (params = {}) { // 获取任务详情列表
    return get(prefix + '/Order/GetClientList', params)
  },
  getOrdersCountList (params = {}) { // 获取任务聚类列表
    return get(prefix + '/Order/GetClientOrderCountList', params)
  },
  getQrCode (params = {}) { // 获取推广码
    return post(prefix + '/QrCode/GetQrCode', params)
  },
  getBonusRecord (params = {}) {
    return post(prefix + '/BonusRecord/GetList', params)
  },
  getRankList (params = {}) {
    return get(prefix + '/Client/GetRankList', params)
  },
  WithDrawalInsert (params = {}) {
    return post(prefix + '/WithDrawal/Insert', params)
  },
  getChildrenList (params = {}) {
    return get(prefix + '/Client/GetChildClientList', params)
  },
  getAuditClientList (params = {}) {
    return get(prefix + '/Client/GetAuditClientList', params)
  },
  AgentAudit (params = {}) { // 审核下级
    return post(prefix + '/Client/AgentAudit', params)
  },
  getSelectList  (params = {}) { // 获取待设置人物
    return get(prefix + '/Client/GetSelectList', params)
  },
  getMissionListByClientId (params = {}) { //获取某人的任务拥挤设置
    return get(prefix + '/MissionPrices/GetMissionPricesByClientId', params)
  },
  MissionPricesSave (params ={}) { // 设置下级的任务佣金比例
    return post(prefix + '/MissionPrices/Save', params)
  },
  GetMyOrderList (params = {}) {
    return get(prefix + '/Order/GetMyOrderList', params)
  },
  GetTeamOrderList (params = {}) {
    return get(prefix + '/Order/GetTeamOrderList', params)
  },
  GetCountInfo (params = {}) { // 获取今日 本月收益统计
    return get(prefix + '/BonusRecord/GetCountInfo', params)
  },
  getNotice (params = {}) { // 获取公告
    return get(prefix + '/Notice/GetList', params)
  },
  getNewNotice  (params = {}) { // 获取最新公告
    return get(prefix + '/Notice/GetNewNotice', params)
  },
  getNoticeDetail (params = {}) { // 获取公告详情
    return get(prefix + '/Notice/Get', params)
  },
  getRate  (params = {}) { // 获取最新全局收益
    return get(prefix + '/Global/Get', params)
  },
  QrCodeGetQrCodeById (params = {}) { // 获取已经领取的任务码
    return get(prefix + '/QrCode/GetQrCodeById', params)
  },
  PriOrderGetList (params = {}) { // 获取专码任务
    return get(prefix + '/PriOrder/GetList', params)
  }
}

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    count: 0,
    wxconfig: {},
    initLink: '',
    // mapKey: 'HJq1Y3ZGYrMWslC887PsJWvuQOJJrmb',
    mapKey: 'XACBZ-QFUC2-KDYUN-CFLBH-P3PCE-75BD7',
    ad_info: {},
    address: ''
  },
  actions: {
    aSaveWxConfig ({state, commit}, p) {
      commit('mSaveWxConfig', p)
    },
    aSetInitLink ({commit}, link) {
      commit('mSetInitLink', link)
    },
    aLoc ({commit}, add) {
      commit('mLoc', add)
    }
  },
  mutations: {
    increment (state) {
      state.count++
    },
    mSaveWxConfig (state, p) {
      state.wxconfig = p
    },
    mSetInitLink (state, link) {
      state.initLink = link
    },
    mLoc (state, {ad_info, address}) {
      window.sessionStorage.setItem('ad_info', JSON.stringify(ad_info))
      state.ad_info = ad_info
      state.address = address
    },
  }
})